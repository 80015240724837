<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗土地流转</div> 
    </div>
    
    <div class="content_box">
      <div class="chart_box">
        <xian-xing-tu class="chart" :value="data1" />
      </div>
    </div>
  </div>
</template>

<script>
  import XianXingTu from '../charts/xian-xing-tu.vue'
  import ZhuZhaungTu from '../charts/zhu-zhuang-tu.vue'
  
  export default {
    name: 'TuDiLiuZhuan',
    components: { ZhuZhaungTu, XianXingTu },
    data() {
      return {
        data1: {},
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 100)
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: {
            text: '',
            left: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '千亩',
          x: [
            '2022年5月', 
            '6月', 
            '7月', 
            '8月', 
            '9月', 
            '10月', 
            '11月', 
            '12月', 
            '2023年1月',
            '2月',
            '3月',
            '4月',
          ],
          y: [4.44, 0.66, 15.5, 10.64, 1.32, 8.88, 14.88, 13.1, 13.54, 6.92, 8.66, 13.32]
        }
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  .box_1 {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-image: url('../../../../assets/images/ba-lin-you-qi/bgc1.png');
    background-size: 100% 100%;

    .header {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-image: url('../../../../assets/images/ba-lin-you-qi/nav-bgc.png');
      background-size: 100% 100%;

      .text {
        width: 50%;
        height: 40px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        // background-color: #2366fc;
        margin: 0 auto;
      }
    }

    .content_box {
      width: 100%;
      height: calc(100% - 50px);
      padding: 0 !important;
      position: relative;

      .chart_box {
        width: 100%;
        height: 100%;
      }
    }

  }
</style>